/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape, string, bool,
} from 'prop-types';
import {  makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    promoPackageBanner: {
        fontWeight: '400',
    },

    promoCodeText: {
        fontWeight: '700',
    },

    promoAmountText: {
        color: theme.palette.cms?.salePriceColor || '#a1001a',
    },
}));

const UrlPromoBanner = ({ urlPromoPackage }) => {
    if (!urlPromoPackage.hasPromo) return null;

    const classes = useStyles();

    /**
     * type mapping
     * p = percentage
     * d = dollar amount
     * f = free shipping
     */
    let amountText;
    if (urlPromoPackage.type === 'p') {
        amountText = `${urlPromoPackage.amount}% off`;
    } else if (urlPromoPackage.type === 'd') {
        amountText = `$${urlPromoPackage.amount} off`;
    } else if (urlPromoPackage.type === 'f') {
        amountText = 'Free shipping';
    }

    return (
        <div className={classes.promoPackageBanner}>

            <span className={classes.promoAmountText}>
                {amountText}
            </span>

            {' with code '}

            <span className={classes.promoCodeText}>
                {urlPromoPackage.displayCode.toUpperCase()}
            </span>
        </div>
    );
};

UrlPromoBanner.propTypes = {
    urlPromoPackage: shape({
        hasPromo: bool.isRequired,
        displayCode: string.isRequired,
        type: string.isRequired,
        amount: string.isRequired,
    }).isRequired,
};

export default UrlPromoBanner;
