/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import QueryString from 'qs';

const validDiscountTypes = {
    p: true, // percentage
    d: true, // dollar
    f: true, // free shipping
};

/**
  * @description Takes a URL query and returns a promo package if one is present.
  * @param {string} URL
  * @returns { {
  *     hasPromo: boolean;
  *     displayCode: string;
  *     type: string;
  *     amount: string;
  * } }
  */
const parser = (URL) => {
    const output = {
        hasPromo: false,
        displayCode: '',
        type: '',
        amount: '',
    };

    if (!URL) {
        output.hasPromo = false;
        return output;
    }

    const urlParams = QueryString.parse(URL.slice(1));
    const hasPromo = urlParams && urlParams?.p && urlParams?.disc;

    // no or missing data
    if (!hasPromo) {
        output.hasPromo = false;
        return output;
    }

    /**
     * Both params are present but is the discount built
     * correctly so we can accuratly parse it?
     */
    const discountType = urlParams.disc[urlParams.disc.length - 1];
    const discountAmount = urlParams.disc.slice(0, urlParams.disc.length - 1);

    /**
     * What makes a valid discount code:
     *  - The discount type MUST be the final character of the disc field
     *  - The amount MUST be defined if type === "d" || type === "p"
     *  - When the amount is defined, it MUST be able to be converted to a Number
     *
     * examples:
     *  good: 20f
     *  good: f
     *  good: 10d
     *  good: 20p
     *
     *  bad: 20a - invalid type
     *  bad: d - no amount
     *  bad: p - no amount
     *  bad: 2ap - invalid amount
     */

    // is the discountType recognized
    if (!validDiscountTypes[discountType]) {
        output.hasPromo = false;
        return output;
    }

    // types p and d
    if (discountType !== 'f' && (!discountAmount || Number.isNaN(Number(discountAmount)))) {
        output.hasPromo = false;
        return output;
    }

    // at this point we know discountType is f so amount doesnt matter

    output.hasPromo = true;
    output.displayCode = urlParams.p;
    output.type = discountType;
    output.amount = discountAmount;

    return output;
};

/**
 * @description Takes a urlPromoPackage and applies the promotion to the provided price.
 * @param {object} urlPromoPackage
 * @param {number} price
 * @returns {number}
 */
const calcPrice = (urlPromoPackage, price) => {
    if (!urlPromoPackage || !price) {
        return null;
    }

    const { amount, type } = urlPromoPackage;

    const nPrice = Number(price);
    const nAmount = Number(amount);
    let output = nPrice;

    if (Number.isNaN(nPrice) || Number.isNaN(nAmount)) {
        return null;
    }

    if (type === 'f') {
        return output;
    }

    if (type === 'p') {
        output *= 1 - (nAmount / 100).toFixed(2);
    }

    if (type === 'd') {
        output -= nAmount;
    }

    return output.toFixed(2);
};

export default {
    parser,
    calcPrice,
};
